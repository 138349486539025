.radio {
	&.highlight {
		padding: 4px 4px 4px 24px;

		&.checked {
			background: #e9e9e9;
			border-radius: @baseBorderRadius;
		}
	}

	/* for keyboard tabbing */
	input[type="radio"]:focus + .radio-label {
		color: @grayLight;
	}
}

.radio-custom {
	position: relative;

	&:after {
		background: transparent;
		border-radius: 8px;
		content: " ";
		height: 8px;
		left: -17px;
		position: absolute;
		top: 4px;
		width: 8px;
	}

	&:before
	{
		border: 1px solid #adadad;
		border-radius: 14px;
		content: " ";
		height: 14px;
		left: -20px;
		position: absolute;
		top: 1px;
		width: 14px;
	}

	&.checked {
		&:after {
			background: #fff;
		}

		&:before {
			background: #39b3d7;
			border-color: #39b3d7;
		}
	}

	&.disabled {
		cursor: not-allowed;

		&:after {
			cursor: not-allowed;
		}
		
		&:before {
			cursor: not-allowed;
			opacity: .5;
		}
	}

	&.radio-inline {
		&:after {
			left: 3px;
			top: 6px;
		}

		&:before {
			left: 0;
			top: 3px;
		}

		&.highlight {
			padding: 4px 4px 4px 24px;

			&:after {
				left: 7px;
				top: 10px;
			}

			&:before {
				left: 4px;
				top: 7px;
			}

			&.checked {
				background: #e9e9e9;
				border-radius: @baseBorderRadius;
			}
		}
	}

	/* for keyboard tabbing */
	input[type="radio"]:focus + .radio-label {
		color: @grayLight;
	}

}
